@import './bootstrap-scss/_functions.scss';
@import './bootstrap-scss/_variables.scss';
@import './bootstrap-scss/_mixins.scss';
@import './bootstrap-scss/_root.scss';
@import './bootstrap-scss/_utilities.scss';

@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  body {
    padding-top: 50px;
  }
}
:root {
  --top-menu-height: 4em;
}

.top-menu {
  height: var(--top-menu-height);
}
.grid {
  width: 100%;
  display: grid;
  grid: var(--top-menu-height) / 1fr;
  grid-template-areas:
    "menu"
    "maincontent";
}

.menu {
  grid-area: menu;
  z-index: 100;
}
.no-border::before {
  display: none;
}
.top-menu i.icon {
  margin: 0 !important;
}

.drop-left-padding {
  padding-left: 0 !important;
}

.label-on-corner {
  top: 0.8em !important;
  left: 78% !important;
}
