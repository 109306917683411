.center_box {
  justify-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.left {
  justify-items: left;
  justify-content: left;
  display: inline-block;
  flex-direction: row;
  width: 100%;
  margin-top: -4rem;
}

.fontTite {
  margin-left: 100px;
  justify-items: left;
  justify-content: left;
  text-align: left;
  display: flex;
  flex-direction: row;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  width: 50%;

  /* identical to box height */

  color: #ffffff;
}
.fontDes {
  margin-left: 100px;
  justify-items: left;
  justify-content: left;
  text-align: left;
  display: flex;
  flex-direction: row;
  /* Sub text */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  width: 70%;
  /* identical to box height, or 169% */

  /* liner */
  background: linear-gradient(180deg, #ffffff 0%, #f4faff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.righta {
  margin-left: 100px;
  justify-items: right;
  justify-content: right;
  text-align: right;
  display: flex;
  float: right;
}
.dot {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background: #307fd1;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
@media (max-height: 810px) and (min-width: 1079px) {
  .top-svg {
    margin-top: 8em;
  }
}
.top-svg {
  margin-top: 0%;
}

@media (max-height: 750px) {
  .image-slider-main {
    max-height: 412px;
    justify-items: start;
    justify-content: start;
    width: 100%;

    margin-top: 0%;
  }

  .font-svg {
    margin-top: -3rem;
  }
}
.image-slider-main {
  display: flex;
  justify-content: center;
  width: 100%;
  justify-items: center;
  min-height: 512px;
}
.font-svg {
  margin-top: -2rem;
}
