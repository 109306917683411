

.logintitle{
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 29px;
margin-left: 15px;
/* identical to box height */

/* Black */
color: #000000;
}

.buttonlogin{

align-items: center;
padding: 15px;
gap: 10px;

width: 120px;
height: 46px;

/* Blue brand */
background: #307FD1;
border-radius: 100px !important;


}
.logindivider{
  display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 0px;
gap: 0px;
}
.buttonforget{


/* Button */
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 14px;

/* identical to box height */
text-align: center;

/* Black */
color: #000000;
background-color: white!important;
border:0px none !important;
border-bottom: 0 none !important;
box-shadow: none;

}

.logininputborder{
  box-sizing: border-box;
  box-shadow: none !important;
width: 340px;
height: 50px;
color: #000000 !important;
/* Border */

border-radius: 9px !important;
margin-bottom: 12px;
}