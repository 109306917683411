#main-header {
  background-color: #fff;
  height: 112px;
  color: var(--main-color);
  position: sticky;
  top: 0;
  z-index: 1000;
}

@media (max-width: 1079px) {
  #main-header {
    height: 62px;
  }
}
#main-header2 {
  position: absolute;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: start;
  justify-items: center;
}

/* #main-header img.logo {
  filter: brightness(100);
} */

.header-mobile-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */

  color: #4f4f4f;
}

.mobile_logo {
  width: 150px;
}

.div-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
}

.login-button {
  width: 110px;
  height: 52px;
  background: #f6f9fc;
  border-radius: 100px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  border: none;
  cursor: pointer;
  /* identical to box height */

  /* Black */
  color: #000000;
}
.register-button {
  width: 140px;
  height: 52px;
  border: none;
  background: #307fd1;
  box-shadow: 0px 30px 16px -20px rgba(53, 80, 133, 0.29);
  border-radius: 100px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
  /* identical to box height */

  /* Black */
  color: #fff;
}

.slicer {
  width: 0px;
  height: 33px;

  /* Border */
  border: 1px solid #e7e7e7;
}

.flex-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}
.center {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-items: center;
  padding: 40px;
}
.vector {
  /* Vector 4 */
  margin: 0px 20px;
  width: 0px;
  height: 33px;
  align-self: center;
  /* Border */
  border: 1px solid #e7e7e7;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */

  /* Black */
  color: #000000;
  text-align: center;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  text-align: left;
  align-self: flex-start;
  /* identical to box height, or 169% */

  /* Subtext */
  color: #4f4f4f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#sidebar-backdrop {
  position: fixed;
  width: 100vw;
  left: 0;
  transition: all 0.3s;
  top: 0;
  height: 100vh;
  z-index: -1;
  opacity: 0;
}
#sidebar-backdrop.show {
  opacity: 1;
  z-index: 900;
  background-color: rgba(0, 0, 0, 0.7);
}

#main-sidebar {
  position: fixed;
  width: 300px;
  color: #fff;
  left: -300px;
  transition: all 0.3s;
  top: 0;
  height: 100vh;
  z-index: 1000;
}
#main-sidebar .ui.menu {
  height: 100%;
}
#main-sidebar.show {
  left: 0;
}

.toggle-sidebar {
  color: #307fd1;
}

.say_hello {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  /* identical to box height, or 138% */

  /* White */
  color: #ffffff;
}

.support_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;

  /* identical to box height, or 169% */

  /* White */
  color: #ffffff;
}
