.insurance-icon {
  width: 100px;
  height: 90px;
  background-image: url("../../assets/img/icons.jpg");
  display: block;
}

.insurance-icon.life {
  background-position: -36px -449px;
}

.insurance-icon.DI {
  background-position: -177px -22px;
}

.insurance-icon.CI {
  background-position: -177px -449px;
}

.custom-age-field {
  padding: 0.75em 1em 0.75em 1em;
  /* Sub text */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  cursor: pointer;

  box-sizing: border-box;

  height: 48px;

  /* Border */
  border: 1.5px solid #e7e7e7;
  border-radius: 8px;
}

.objectives .column:nth-child(1) {
  order: 1;
}

.objectives .column:nth-child(2) {
  order: 2;
}

.objectives .column:nth-child(3) {
  order: 4;
}

.objectives .column:nth-child(4) {
  order: 5;
}

.objectives .column:nth-child(5) {
  order: 3;
}

.ui.grid.insurance-result .row .column {
  display: flex;
  align-items: center;
}

.button-result {
  border: 1.5px solid #105399;
  border-radius: 100px;
  width: 115px;
  background-color: #fff;
  height: 46px;
}

.ui.grid.insurance-result {
  background: #ffffff;

  /* Border */
  border: 1.5px solid #e7e7e7;
  border-radius: 8px 8px 0px 8px;
  height: 100px;
}

.ui.grid.insurance-result-details {
  background: #fafafa;

  /* Border */
  border: 1.5px solid #e7e7e7;
  border-radius: 0px 0px 8px 8px;
  height: 3.5rem;
  width: 98.8%;
  margin-left: 0.65rem;
  /* width: 100%; */
}
.qoute-results header .ui.grid {
  background-color: #fafafa;
}
.qoute-results .column {
  display: flex;
  align-items: center;
}
.qoute-results article {
  margin: 1.5rem 0;
}
.qoute-results article > div {
  border: 1px solid #ddd;
}
.qoute-results article:nth-child(even) > div {
  background-color: #eee;
}
.qoute-results article .apply {
  justify-content: center;
}

i.question {
  margin-left: 0.25rem;
  display: inline-block;
  height: 28px;
}
.section i.question,
.item i.question {
  height: 20px;
}
.motion-disabled i.question {
  display: none;
}

.amazing {
  max-width: 0;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  transition: all 1s;
}

.load .amazing {
  max-width: 300px;
}

#settings {
  display: block;
  position: fixed;
  bottom: 320px;
  right: -142px;
  z-index: 101;
  overflow: hidden;
  transition: all 0.3s;
}
#settings:hover {
  right: -12px;
}

#settings i {
  width: 46px;
  display: inline-flex;
  justify-content: center;
  font-size: 1.5rem;
}
#settings i::before {
  animation: icon-loading 2s linear infinite;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}

/* #policy-grid{
  max-height: 0;
  display: flex;
  overflow: hidden;
  transition: all 1s;
}

.load #policy-grid{
  max-height: 10000px;
  display: flex;
  overflow: hidden;
  transition: all 1s;
} */

#motion-wrapper {
  background-color: #fff;
}

.insurance-loading {
  height: 200px;
  position: relative;
}

.recommendation svg.icon {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
}

@media (max-width: 992px) {
  main .ui.slider:not(.vertical):not(.checkbox) {
    padding: 2em 1.5em;
    max-width: 100vw;
    overflow-x: hidden;
  }
  .ui.labeled.slider > .labels .label {
    font-size: 0.8rem;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .ui.grid > .row.section-wrapper > [class*="three wide"].column {
    width: 25% !important;
  }
  .ui.grid > .row.section-wrapper > [class*="four wide"].column {
    width: 33% !important;
  }
  .ui.list.goals.target > .item:last-child > .content {
    min-width: 150px;
  }
  .w-50 {
    width: 65% !important;
  }
}

body.mac .eagle {
  bottom: -10px;
}

@media (max-width: 767px) {
  .ui.container > .ui.stackable.grid > .column,
  .ui.container > .ui.stackable.grid > .row > .column {
    padding: 1rem 1rem !important;
  }

  .ui.wide.popup,
  .ui[class*="very wide"].popup {
    max-width: 95vw !important;
  }

  .eagle {
    top: calc(100% - 240px);
    bottom: auto;
    right: -32px;
    margin: 118px -61px 0 0 !important;
  }

  body.mac .eagle {
    top: calc(100% - 218px);
    right: -21px;
    height: 210px;
    width: 210px;
    max-width: none;
  }

  #eagles {
    transform: scale(0.8);
    transform-origin: 100% 0;
    z-index: 10;
    width: 84px;
    height: 84px;
    overflow: hidden;
    display: block;
    position: fixed;
    bottom: -8px;
    right: 6px;
    border-radius: 50%;
    filter: drop-shadow(2px 4px 6px black);
    background: #073f5a;
  }

  #settings {
    bottom: 120px;
  }
}

.titletext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  /* Black */
  color: #000000;
}

.centerQuote {
  position: absolute;
  justify-items: center;
  justify-content: center;
  display: flex;
  top: 20%;
}

.headerarrowdiv {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;

  min-width: 503px;
  background-color: yellowgreen;
}

.mainNav {
  position: fixed;
  align-items: center;
  top: 0;
  left: 0%;
  width: 28rem;

  min-height: 100vh;
  background-color: #ffffff;
  padding: 58px 45px;
  border-right: 1px solid #e7e7e7;
  z-index: 3;
  justify-content: left;
  margin: 0px;
  transition: transform 0.25s ease;
  overflow-y: auto;
  justify-content: center;
  justify-items: center;

  flex-direction: row;
}
@media (max-height: 810px) {
  .mainNav ul {
    margin-top: -20px;
  }
}
/* className="images-container-resize60" */
@media (max-width: 390px) {
  .images-container-resize60 {
    width: 60px !important;
    height: 60px !important;
  }
  .ourservicesvg {
    display: none;
  }
}

@media (max-width: 1200px) {
  .mainNav {
    width: 25rem;
  }
  .sub-nav-li-bottom {
    width: 24.9rem !important;
  }
  .childMain {
    width: 70% !important;
    transform: translate(22%, 0) !important;
  }
  .mainNav ul {
    margin-left: -2rem;
  }
}
@media (max-width: 1100px) {
  .mainNav {
    width: 24rem;
  }
  .sub-nav-li-bottom {
    width: 23.9rem !important;
  }
  .childMain {
    width: 70% !important;
  }
  .mainNav ul {
    margin-left: -2.5rem;
  }
}

.mainNav ul {
  margin-left: -2rem;
}

.nav-li-start {
  justify-content: left;
  justify-items: flex-start;
  display: flex;
  flex-direction: row;
}

.nav-li-bottom {
  justify-content: center;
  justify-items: center;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  bottom: 0px;
  left: 0%;
  align-self: center;
  background: #f6f9fc;
  width: 100%;
}

.sub-nav-li-bottom {
  position: fixed;
  align-self: center;
  justify-content: center;
  justify-items: center;
  display: flex;
  width: 100%;
  bottom: 0px;
  background: #f6f9fc;
  left: 0%;
  width: 27.9rem;
  padding-bottom: 1.4rem;
}

.steps-div {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
}

.steps-div-circle {
  color: #fff;
  border-radius: 50%;
  min-width: 35px !important;
  min-height: 35px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.steps-div-title {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;
}

.steps-div-line {
  width: 0px;
  height: 42px;

  /* Border */
  border: 1px solid #e7e7e7;
}

/*desktop Substep */
.connected-subSteps {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: start;
  justify-content: start;
  flex-direction: column;
}

.subStep {
  color: white;
  background-color: red;
  display: block;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  justify-content: center;
  display: flex;
}

.connector-subSteps {
  flex-grow: 1;
  height: 10px;
  content: "";
  display: block;
  width: 2px;
  background-color: #e7e7e7;
}

/*mobile step */
.connected-steps {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.step {
  color: white;
  background-color: red;
  display: block;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  justify-content: center;
  display: flex;
}

.connector {
  flex-grow: 1;
  width: 10px;
  content: "";
  display: block;
  height: 2px;
  background-color: #e7e7e7;
}
.connector-white {
  flex-grow: 1;
  width: 10px;
  content: "";
  display: block;
  height: 1px;
  background-color: white;
}

.mainNav2 {
  position: fixed;
  align-items: center;

  top: 0;
  right: 0%;

  min-height: 100vh;

  padding: 45px 15px;

  z-index: 3;
  justify-content: center;

  overflow-y: auto;
  width: 400px;
  display: flex;

  background: linear-gradient(
    18.27deg,
    #f6f9fc 21.9%,
    rgba(246, 249, 252, 0) 90.38%
  );
}

.right-side-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 25px;

  width: 340px;
}
.right-side-div-head {
  display: flex;

  padding: 0px;
  gap: 12px;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
}

.right-side-div-head-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
}
.right-side-box-detail {
  /* Frame 67 */
  min-height: 15em;
  width: 340px;

  display: flex;

  /* White */
  background: rgba(241, 215, 149, 0.28);
  border-radius: 8px;

  padding: 15px;
}

.right-side-box-detail-subtext {
  /* Sub text */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  white-space: pre-line;
  /* or 169% */

  color: #73612f;
}

.right-side-box-phone {
  /* Frame 67 */

  width: 340px;
  height: 84px;
  display: flex;

  /* White */
  background: #ffffff;
  box-shadow: 0px 21px 20px -9px rgba(15, 51, 88, 0.09);
  border-radius: 8px;

  padding: 15px;
}

.right-side-box-phone-inside {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  padding: 0px;
  width: 100%;
  gap: 12px;
}

.quote_header {
  visibility: hidden;
}

.quote_header_eagle {
  position: absolute;
  top: 1rem;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.help-eagle-desktop {
  position: fixed;
  right: 1rem;
  bottom: 2rem;
  z-index: 10;
  cursor: pointer;
  display: none;
}
@media screen and (max-width: 1550px) {
  .mainNav2 {
    display: none;
  }

  .help-eagle-desktop {
    display: block;
  }
  .quote_header {
    visibility: visible;
  }
  .childMain {
    transform: translate(25%, 0);
  }
}

.mainForm {
  width: 100%;
  justify-content: center;
  justify-items: center;
  display: flex;

  /* transform: translate(400px, 0); */
}

.circle_check_mark {
  height: 32px;
  width: 32px;
  background-color: #bbb;
}

.childMain {
  padding: 0rem 2.2rem 4rem 2.2rem;
  max-width: 800px;
  align-items: flex-start;
  justify-content: left;
  /* transform: translate(400px, 0); */
}

.resultfilter {
  display: inline-flex;
  background: #fff;

  height: 30px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 3px;
  border: 1.5px solid #e7e7e7;
  border-radius: 100px;
}
.resultfilter .option {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  gap: 10px;

  width: 79px;
  height: 30px;

  border-radius: 100px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  transition: all 0.8s ease;
}

.inputfilter[type="radio"] {
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
  border-color: #0069d9;
  background: #0069d9;
}

.resultfilter .option .text-filter {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}
#option-1:checked:checked ~ .option-1 .text-filter,
#option-2:checked:checked ~ .option-2 .text-filter {
  /* Button */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  /* identical to box height */
  text-align: center;

  /* Black */
  color: #fff;
}

.list-item {
  width: max-content;
  height: 100%;

  margin-left: 10px;
  display: inline-block;
  justify-content: center;
  justify-items: center;
  padding-bottom: 20px;
}

.scroll_list {
  height: max-content;
  overflow-x: scroll;

  overflow-y: hidden;
  white-space: nowrap;
  width: auto;
  justify-content: center;
  justify-items: center;
  border-bottom: 1.5px solid #e7e7e7;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll_list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.scroll_list {
  -ms-overflow-style: none;
}

@media (max-width: 1079px) {
  .mainForm {
    width: 100%;
    height: 100%;
    /* transform: translate(400px, 0); */
  }

  .childMain {
    padding: 1rem 0rem;
    width: 100% !important;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 10rem;
    height: 100%;
    transform: translate(0, 0) !important;
  }

  .titletext {
    /* h3 */
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    /* Black */
    color: #000000;
  }

  .titletext:hover {
    /* Black */
    color: #105399;
  }
  .homecardtitletext {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 19px;
    width: 100%;
  }

  .homecardtitledec {
    /* Sub text */
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    margin-top: -0.5rem;

    /* or 169% */

    /* Subtext */
    color: #4f4f4f;
  }

  .ui.grid.insurance-result {
    background: #ffffff;

    /* Border */
    border: 1.5px solid #e7e7e7;
    border-radius: 8px;
    height: max-content;
  }

  .percent-container {
    margin-top: 0rem !important;
    margin-inline: 0rem !important;
  }

  .resposive-input-size {
    width: 100% !important;
  }
 
}

.percent-container {
  margin-top: 2.8rem;
  margin-inline: 1rem;
}

/* recommend qoute box */
.recomended_box {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  gap: 1rem;
  height: 79px;
  width: 100%;
  background: rgba(149, 186, 241, 0.28);
  border-radius: 8px;
  padding: 0rem 1.4rem;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}
.recomended_text {
  /* h3 */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  /* Black */
  color: #105399;
}

.recomended_box_detail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px 30px;
  /* Back blue */
  background: #f6f9fc;
  border-radius: 10px;

  /* identical to box height */

  /* Dark blue title */
  color: #105399;
}

.w-800 {
  width: 800px;
}

.pb-200 {
  padding-bottom: 200px;
}

.basic-info-bullet {
  width: 18px;
  height: 18px;
  border: 5px solid #307fd1;
  background: #fff;
  border-radius: 50%;
  pointer-events: none;
  user-select: none;
  z-index: 1;
  cursor: pointer;
  margin-right: 10px;
}

.resposive-input-size {
  width: 50%;
}

.help-eagle-mobile {
  position: fixed;
  right: 1rem;
  bottom: 2rem;
  z-index: 10;
  cursor: pointer;
}
.layout-mobile-enter {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  position: absolute;
  top: 25%;
}

.max-content {
  max-width: max-content;
}

.modal-yes-button {
  background: #307fd1;
  border-radius: 8px;
  border: none;
  color: #fff;
}
.modal-no-button {
  background: #f2f6fa;
  border-radius: 8px;
  border: none;
}

.bottom-navigation-next-button {
  width: 100%;
  position: fixed;
  bottom: 0% !important;
  left: 0%;
  border-top: "1px solid #E7E7E7";
  background: #f6f9fc;
  display: flex;
  justify-content: center;
  justify-items: center;
  height: max-content;
  padding-bottom: 1rem;
}


.back-to-home{
  min-width: 182px;
  height: 44px;
  display: flex;
  justify-content: center;
  justify-items: center;
  background: #1678c2 !important;
  border-radius: 100px;
  padding-top:10px;
  color:#ffffff;
  margin-left: 0rem;
}