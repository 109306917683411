body {
  margin: 0;
  font-family: "Inter-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(./app/assets/fonts/Inter-Regular.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(./app/assets/fonts/Inter-Light.ttf) format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(./app/assets/fonts/Inter-ExtraBold.ttf) format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(./app/assets/fonts/Inter-Medium.ttf) format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./app/assets/fonts/Inter-Bold.ttf) format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(./app/assets/fonts/Inter-SemiBold.ttf) format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(./app/assets/fonts/Inter-ExtraLight.ttf) format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./app/assets/fonts/Inter-Thin.ttf) format("truetype");
  font-weight: 100;
}
code {
  font-family: Inter, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
