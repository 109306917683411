#main-footer {
  background-color: #fff;
  color: #4f4f4f;
}
#main-footer a {
  color: var(--secondary-text-color);
}
#main-footer a.letter-spacing {
  letter-spacing: 2px;
}

#main-footer i {
  margin-right: 0.25rem;
}

#main-footer h3 {
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}
#main-footer h3:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100px;

  opacity: 0.3;
  display: block;
  bottom: 0;
}

.footer-desktop-download {
  height: 100%;
  justify-content: center;
  justify-items: center;
  align-self: center;
  flex-grow: 1;
  margin-top: -20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.footer-desktop-download a {
  justify-content: center;
  justify-items: center;
  align-self: center;
  flex-grow: 1;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.footer-mobile-download {
  height: 100%;
  justify-content: center;
  justify-items: center;
  align-self: center;
  flex-grow: 1;
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.footer-mobile-download a {
  justify-content: center;
  justify-items: center;
  align-self: center;
  flex-grow: 1;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.grey.linkedin.large.inverted.icon:hover {
  color: #307fd1 !important;
}

.grey.twitter.large.inverted.icon:hover {
  color: #307fd1 !important;
}

.grey.instagram.large.inverted.icon:hover {
  color: #307fd1 !important;
}

.grey.facebook.large.inverted.icon:hover {
  color: #307fd1 !important;
}
